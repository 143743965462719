/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        //== WOW.js
        //
        new WOW({offset: 0}).init();

        //== Hamurger Menu
        //
        var htmlEl = $('html'),
        bodyEl = $('body'),
        content = $('main'),
        menubtn = $('button.hamburger'),
        isOpen = false;

        function toggleMenu() {

          if ( bodyEl.hasClass("project-modal-open") ) {
            $('.modal').modal('hide');
          }
          else {

            if( isOpen ) {
              menubtn.removeClass('is-active');
              bodyEl.removeClass( 'open-menu' );
              htmlEl.removeClass( 'no-scroll' );
            }
            else {
              menubtn.addClass('is-active');
              bodyEl.addClass( 'open-menu' );
              htmlEl.addClass( 'no-scroll' );
            }
            isOpen = !isOpen;

          }

        }

        menubtn.click( function() {
          toggleMenu();
        });

        content.click( function() {
          if ( isOpen ) {
            toggleMenu();
          }
        });

        $(document).on('keyup',function(evt) {
            if (evt.keyCode === 27) {
              if ( isOpen ) {
                toggleMenu();
              }
            }
        });

        //== Projects Modalbox
        //
        $('.modal').on('show.bs.modal', function (e) {
          bodyEl.addClass("project-modal-open");
          menubtn.addClass('is-active');
        }).on('shown.bs.modal', function (e) {

          var $project_slideshow = $(this).find('.project-slideshow').slick({
            infinite: false,
            arrows: true,
            draggable: true
          }).on('init', function( event, slick ) {
            $(this).addClass('show');
          });

        }).on('hide.bs.modal', function (e) {

          bodyEl.removeClass("project-modal-open");
          menubtn.removeClass('is-active');

        }).on('hidden.bs.modal', function (e) {


        });

        //== Slick Slider
        //
        var projects_settings = {
          infinite: false,
          arrows: false,
          draggable: false,
          cssEase: 'cubic-bezier(0.7,0,0.3,1)',
        };

        // Projects Slider
        $('.projects-slider').each(function(key, item) {

          var sliderIdName = 'slider' + key;
          var sliderNavIdName = 'sliderNav' + key;

          this.id = sliderIdName;
          var $projects_slider = $('#' + sliderIdName).slick(projects_settings);

          $projects_slider.on('afterChange', function(event, slick, currentSlide, nextSlide){

            if (currentSlide !== 0) {
              $(this).closest('.section').find(".less-projects").removeClass("disabled");
            } else {
              $(this).closest('.section').find(".less-projects").addClass("disabled");
            }

            if (slick.$slides.length === currentSlide + slick.options.slidesToScroll) {
              $(this).closest('.section').find(".more-projects").addClass("disabled");
            } else {
              $(this).closest('.section').find(".more-projects").removeClass("disabled");
            }

            $(this).closest('.section').find('.projects-slider-pagination .current-page').text( slick.currentSlide + 1 );

          });

        });

        $(".more-projects").click( function(e) {
          e.preventDefault();
          $(this).closest(".section").find(".projects-slider").slick("slickNext");
        } );

        $(".less-projects").click( function(e) {
          e.preventDefault();
          $(this).closest(".section").find(".projects-slider").slick("slickPrev");
        } );


        // Partner Slider
        var partner_settings = {
          infinite: true,
          arrows: true,
          draggable: false,
          slidesToShow: 3,
          slidesToScroll: 1,
          cssEase: 'cubic-bezier(0.7,0,0.3,1)',
        };

        var $partner_slider = $('#partner-slider').slick(partner_settings);

        // Testimonials Slider
        const testimonials_settings = {
          infinite: false,
          arrows: true,
          draggable: false,
          cssEase: 'cubic-bezier(0.7,0,0.3,1)',
          adaptiveHeight: true
        };

        if ( $('.testimonials-slider').length > 0 ) {
          $('.testimonials-slider').each(function(key, item) {
            var sliderIdName = 'testimonialSlider' + key;
            this.id = sliderIdName;
            var count_testimonials = $(this).children('.testimonial').length;
            
            if ( count_testimonials > 1 ) {
              var $testimonials_slider = $('#' + sliderIdName).slick(testimonials_settings);
            }
          });
        }

        $(".scroll-btn").click( function(e) {
          e.preventDefault();
          $('html, body').stop().animate({'scrollTop': $("#einleitung").offset().top -60 }, 1200, 'easeInOutExpo');
        } );

        //== Google Maps
        //
        function add_marker( $marker, map ) {

          // var
          var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );
          var icon = new google.maps.MarkerImage(
            php_vars.dist_path + 'images/marker.svg',
            new google.maps.Size(100, 144),
            new google.maps.Point(0, 0),
            new google.maps.Point(100, 144)
          );

          // create marker
          var marker = new google.maps.Marker({
            position  : latlng,
            map     : map,
            icon: icon,
          });

          // add to array
          map.markers.push( marker );

          // if marker contains HTML, add it to an infoWindow
          if( $marker.html() ) {
            // create info window
            var infowindow = new google.maps.InfoWindow({
              content   : $marker.html()
            });

            // show info window when marker is clicked
            google.maps.event.addListener(marker, 'click', function() {
              infowindow.open( map, marker );
            });
          }

        }

        function center_map( map ) {

          // vars
          var bounds = new google.maps.LatLngBounds();

          // loop through all markers and create bounds
          $.each( map.markers, function( i, marker ){

            var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

            bounds.extend( latlng );

          });

          // only 1 marker?
          if( map.markers.length === 1 ) {
            // set center of map
              map.setCenter( bounds.getCenter() );
              map.setZoom( 16 );
          } else {
            // fit to bounds
            map.fitBounds( bounds );
          }

        }

        function new_map( $el ) {

          // var
          var $markers = $el.find('.marker');

          // vars
          var args = {
            zoom    : 16,
            center    : new google.maps.LatLng(0, 0),
            mapTypeId : google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            styles: [
              {
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#0c0c0c"
                  }
                ]
              },
              {
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#666666"
                  }
                ]
              },
              {
                "elementType": "labels.text.stroke",
                "stylers": [
                  {
                    "visibility": "off"
                  }
                ]
              },
              {
                "featureType": "landscape.natural",
                "elementType": "labels.icon",
                "stylers": [
                  {
                    "visibility": "off"
                  }
                ]
              },
              {
                "featureType": "poi",
                "elementType": "labels.icon",
                "stylers": [
                  {
                    "saturation": -100
                  },
                  {
                    "visibility": "off"
                  }
                ]
              },
              {
                "featureType": "poi",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#666666"
                  }
                ]
              },
              {
                "featureType": "poi",
                "elementType": "labels.text.stroke",
                "stylers": [
                  {
                    "visibility": "off"
                  }
                ]
              },
              {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#212121"
                  }
                ]
              },
              {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#38414e"
                  }
                ]
              },
              {
                "featureType": "road",
                "elementType": "geometry.fill",
                "stylers": [
                  {
                    "color": "#000000"
                  }
                ]
              },
              {
                "featureType": "road",
                "elementType": "geometry.stroke",
                "stylers": [
                  {
                    "color": "#1b1b1b"
                  }
                ]
              },
              {
                "featureType": "road",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#666666"
                  }
                ]
              },
              {
                "featureType": "road.arterial",
                "elementType": "labels.icon",
                "stylers": [
                  {
                    "visibility": "off"
                  }
                ]
              },
              {
                "featureType": "road.highway",
                "elementType": "geometry.fill",
                "stylers": [
                  {
                    "color": "#0f0f0f"
                  }
                ]
              },
              {
                "featureType": "road.highway",
                "elementType": "geometry.stroke",
                "stylers": [
                  {
                    "color": "#1d1d1d"
                  }
                ]
              },
              {
                "featureType": "road.highway",
                "elementType": "labels.icon",
                "stylers": [
                  {
                    "visibility": "off"
                  }
                ]
              },
              {
                "featureType": "transit",
                "elementType": "labels.icon",
                "stylers": [
                  {
                    "saturation": -100
                  },
                  {
                    "visibility": "off"
                  }
                ]
              },
              {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                  {
                    "color": "#2c2c2c"
                  }
                ]
              },
              {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [
                  {
                    "color": "#515c6d"
                  }
                ]
              },
              {
                "featureType": "water",
                "elementType": "labels.text.stroke",
                "stylers": [
                  {
                    "color": "#17263c"
                  }
                ]
              }
            ]};

          // create map
          var map = new google.maps.Map( $el[0], args);

          // add a markers reference
          map.markers = [];

          // add markers
          $markers.each(function() {
              add_marker( $(this), map );
          });

          // center map
          center_map( map );

          // return
          return map;

        }

        // Shrink Navigation
        function scrollFunction() {
          if ( $(document).width() > 1024 ) {
            if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
              $("body").addClass("shrink");
            } else {
              $("body").removeClass("shrink");
            }
          }
        }
        window.onscroll = function() { scrollFunction(); };

        $(window).on('resize load', function() {
          $('ul#menu-primary-navigation li a').click(function(e) {
            e.preventDefault();
            var target = this.hash,
            $target = $(target);
            toggleMenu();

            setTimeout( function() {
              $('html, body').stop().animate({'scrollTop': $target.offset().top -60 }, 1200, 'easeInOutExpo');
            }, 450);
          });

          // reslick only if it's not slick()
          if ( $(document).width() < 991 ) {
            $('.slick-initialized').each(function(key, item) {
              if ( !$(item).hasClass('testimonials-slider') ) {
                $(this).slick('unslick');
              }
            });
            return;
          }

          // Re slick projects slider
          $('.projects-slider').each(function(key, item) {
            if ( !$(this).hasClass('slick-initialized') ) {
              var sliderIdName = 'slider' + key;
              return $('#' + sliderIdName).slick(projects_settings);
            }
          });

          // Re slick partner slider
          if ( !$partner_slider.hasClass('slick-initialized') ) {
            return $partner_slider.slick( partner_settings );
          }
        });

        //== Contact Google Maps
        //
        var map = null;
        // Add Map to Contact section
        $('.acf-map').each(function(){
          map = new_map( $(this) );
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };


  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
